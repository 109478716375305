import dataNames from '../../data/names.json'
import { Text, Progress, Box } from "@chakra-ui/react";
import HelperLocalStorage from "../../data/helperLocalstorage";


const ProgressBar = () => {

	/*
		Total Number of Names = 99 into an Array
		Get number of Names Memorized from localStorage i.e where TRUE
		Calculate Percentage
	*/
	let nameMemorized: number = 0;
	dataNames.map((name) => (
		HelperLocalStorage.isNameMemorized(name["ID"]) ? nameMemorized++ : nameMemorized + 0
	))

	const percentage: number = (nameMemorized / 99) * 100


	return (

		<Box>

			<Text as="b">Names Memorized: {nameMemorized}</Text>

			<Progress
				value={percentage}
				colorScheme='pink'
				hasStripe
				size={"lg"}
			>{percentage}%</Progress>

		</Box>

	)
}



export default ProgressBar;