import { useEffect, useState } from 'react';

import ReactMarkdown from 'react-markdown';

import NameExp from '../../data/asset-names'

import { Box } from '@chakra-ui/react';


/*
		 Takes in a Name number and displays the corresponding MD file
*/


type Props = {
	nameNumber: number
}


const NameExplanation = ({ nameNumber }: Props) => {

	const [markdown, setMarkdown] = useState('')

	useEffect(() => {

		fetch(NameExp[nameNumber - 1])
			.then(response => {
				return response.text()
			})
			.then(text => {
				setMarkdown(text)
			})
	}, [nameNumber]);

	return (

		<Box shadow={"md"} mb={10} >

			<Box p={5}>
				{
					markdown === '' &&
					<div className="spinner-border primaryColour1" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				}
				<ReactMarkdown>{markdown}</ReactMarkdown>
			</Box>

		</Box>
	);
}

export default NameExplanation;