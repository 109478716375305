
interface Constants {
	KEY_BookmarkedName: string,
	KEY_NameMemory: string,
	KEY_HigherScore_Q1: string,
	KEY_HigherScore_Q2: string,
	KEY_HigherScore_Q3: string,
	KEY_HigherScore_Q4: string,
	KEY_Quiz1_Attempts: string,
	KEY_Quiz2_Attempts: string,
	KEY_Quiz3_Attempts: string,
	KEY_Quiz4_Attempts: string,
}


const constants: Readonly<Constants> =
{
	KEY_BookmarkedName: "bookmarkedName",
	KEY_NameMemory: "nameMemory",
	KEY_HigherScore_Q1: "quiz1_HighestScore",
	KEY_HigherScore_Q2: "quiz2_HighestScore",
	KEY_HigherScore_Q3: "quiz3_HighestScore",
	KEY_HigherScore_Q4: "quiz4_HighestScore",
	KEY_Quiz1_Attempts: "quiz1_Attempts",
	KEY_Quiz2_Attempts: "quiz2_Attempts",
	KEY_Quiz3_Attempts: "quiz3_Attempts",
	KEY_Quiz4_Attempts: "quiz45_Attempts",

}

export default constants