import { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";

import {
	Box,
	Container,
	Heading,
	HStack,
	Input,	
	SimpleGrid,
	Tag,
	Text
} from '@chakra-ui/react';

// Data
import dataNames from '../data/names.json'




const Names = () => {

	const [dataSearchNames, setSearchNames] = useState(dataNames) // Default use the Names JSON

	const [searchTerm, setSearchTerm] = useState('')

	const handleSearchChange = (event: any) => {
		setSearchTerm(event.target.value)
	}


	// Filter Search
	useEffect(() => {

		if (searchTerm === '') {
			setSearchNames(dataNames)
		}
		else {
			const searchNames = dataNames.filter((name: any) => {
				return name.IPA.toLowerCase().search(searchTerm.toLowerCase()) !== -1
			})
			setSearchNames(searchNames)
		}

	}, [searchTerm]);

	const navigate = useNavigate();


	return (

		<Container maxW='container.sm' mt={10} mb={20} >

			<Heading>Names</Heading>

			<Text>Click on a name for more information</Text>


			<Input
				value={searchTerm}
				onChange={handleSearchChange}
				placeholder='Search e.g. Rahman'
				mb={5}
				mt={5}
			/>

			<SimpleGrid spacing={5}>
				{dataSearchNames.map((name) => (

					<Box
						key={name.ID}
						boxShadow="md"
						p={2}
						onClick={() => navigate("/" + name["ID"])}
						cursor={"pointer"}
					>

						<HStack>
							<Tag size={"sm"}>{name.ID}</Tag>
							<Text minWidth={120}>{name["IPA"]} </Text>
							<Text dir="rtl" className="arabic brand-font">{name["Classical Arabic"]} </Text>


						</HStack>
						<Text fontSize={"sm"}>{name["Translation"]} </Text>

					</Box>

				))}

			</SimpleGrid >

		</Container >
	)
}

export default Names;