import { useState } from "react";

import ReactAudioPlayer from 'react-audio-player';

// Audio Files
import audioNames from '../../data/asset-audio'
import { IconButton } from "@chakra-ui/react";
import { FaPlay } from "react-icons/fa";




type Props = {
	nameNumber: number
}


const NameAudio = ({ nameNumber }: Props) => {

	// Declare a new state variable, which we'll call "count"
	const [playing, setPlaying] = useState(false);
	// console.log(audioNames[this.props.audioNumber])

	return (
		<>
			<IconButton
				aria-label='Play Icon'
				icon={<FaPlay />}
				disabled={playing}
				onClick={() => setPlaying(true)}
			/>

			{
				playing === true &&
				<ReactAudioPlayer
					src={audioNames[nameNumber]}
					autoPlay={playing}
					crossOrigin="anonymous"
					onEnded={() => setPlaying(false)}
				/>
			}

		</>
	);

}

export default NameAudio;