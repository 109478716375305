import { Button, Container, Heading, Link, Text, Wrap } from '@chakra-ui/react'
import { FaExternalLinkAlt } from 'react-icons/fa';


const QuizHome = () => {

	return (

		<Container maxW='container.md' mt={10} mb={20}>

			<Heading>Quiz</Heading>

			<Text>From version 2.0 we have moved the Quiz to a Google Form. This way, we can track the number of users who have completed the test and also the scores.
			</Text>

			<Wrap>
				<Link href="https://docs.google.com/forms/d/e/1FAIpQLSdiSZ7WzmMoZAu2qMFFGn4ZvX9eGwLoJi5x2XW0Ob7zrRbGRg/viewform" isExternal>
					<Button>
						<FaExternalLinkAlt />
						<Text ml={2}>Quiz (All 99 Names) </Text>
					</Button>
				</Link >


				<Link href="https://docs.google.com/forms/d/e/1FAIpQLSfmS0_EdXjzHfWGoh93BckO_NUH-aw8Fd2v66gezdQyGTZeRQ/viewform" isExternal>
					<Button>
						<FaExternalLinkAlt />
						<Text ml={2}>Quiz 1 (1-24 Names) </Text>
					</Button>
				</Link >


				<Link href="https://docs.google.com/forms/d/e/1FAIpQLSeFqciTB4SCOVOL-r_K4GN6rugkBv4c8zlNg-D9iPlkd-uNxQ/viewform" isExternal>
					<Button>
						<FaExternalLinkAlt />
						<Text ml={2}>Quiz 2 (25-49 Names) </Text>
					</Button>
				</Link >


				<Link href="https://docs.google.com/forms/d/e/1FAIpQLSc954Rnduj4x_ll7Kl1gNPvaQHS5MgMhkGEyDmB3vy7E619Yw/viewform" isExternal>
					<Button>
						<FaExternalLinkAlt />
						<Text ml={2}>Quiz 3 (50-74 Names) </Text>
					</Button>
				</Link >

				<Link href="https://docs.google.com/forms/d/e/1FAIpQLSfgnTUbWgEYYTkN3xcl5Ys-b5O4PnDmo3paEe9Ux3AOVUsfZw/viewform" isExternal>
					<Button>
						<FaExternalLinkAlt />
						<Text ml={2}>Quiz 4 (75-99 Names) </Text>
					</Button>
				</Link >

			</Wrap>

		</Container >
	);
}


export default QuizHome;