import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { RHAP_UI } from "react-h5-audio-player/lib/constants"


import {
	Box,
	FormControl,
	FormLabel,
	Heading,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Text
} from '@chakra-ui/react';

/*
import the URLs for each episode
on playing it must start at one
repeat if user wants to have the audio repeat

*/

import AudioURLs from '../data/asset-audio'
import React from 'react';



const AudioAllNames = () => {

	// get URLS

	const [audioIndex, setAudioIndex] = React.useState(0)
	const [audioSrc, setAudioSrc] = React.useState(AudioURLs[audioIndex])

	const [audioRepeatTracker, setAudioRepeatTracker] = React.useState(1)
	const [audioPlayed, setAudioPlayed] = React.useState(0)

	// control playback, if user presses the play button it must start
	// console.log(AudioURLs)

	// Handles the logic for when the audio as played til the end
	const onEnded = () => {

		const audioPlayedCounter = audioPlayed + 1
		setAudioPlayed(audioPlayedCounter)

		console.log("Audio has played = " + audioPlayedCounter)

		// Assume we need to repeat
		let nextName = audioIndex

		// If we don't need to repeat continue to next name
		if (audioRepeatTracker <= (audioPlayedCounter)) {
			nextName = audioIndex + 1
			setAudioIndex(nextName)
			console.log("Next Name = " + nextName)
			setAudioPlayed(0)
		}

		// If we not at the end of the list
		if (nextName !== 99) {
			const url = AudioURLs[nextName]
			setAudioSrc('')
			setAudioSrc(url)
		}
	}


	const handleRepeatTracker = (event: any) => {

		// console.log({ event })
		let val = parseInt(event)
		setAudioRepeatTracker(val)
	}




	return (

		<Box mb={10} mt={5}>

			<Heading as="h2" fontSize={"2xl"}>99 Names</Heading>

			<Text>Listen to all the 99 Names. Enable/Disable Loop.</Text>

			<FormControl mt={5} mb={5}>
				<FormLabel htmlFor='email'>Repeat each Name: </FormLabel>
				<NumberInput size='sm' maxW={20} defaultValue={1} min={1} max={50} onChange={handleRepeatTracker}>
					<NumberInputField />
					<NumberInputStepper>
						<NumberIncrementStepper />
						<NumberDecrementStepper />
					</NumberInputStepper>
				</NumberInput>
			</FormControl>

			<AudioPlayer
				src={audioSrc}
				onEnded={() => onEnded()}
				customControlsSection={[RHAP_UI.MAIN_CONTROLS, RHAP_UI.VOLUME_CONTROLS]}
			/>

		</Box>

	);
}

export default AudioAllNames;