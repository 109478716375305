import Hadith from './ref-hadith'
import Quran from './ref-quran'

import { Box, Container, Heading, Text } from '@chakra-ui/react'


const References = () => {

	return (

		<Container maxW='container.sm' mt={10} mb={20}>

			<Box mb={5}>
				<Heading as="h1">References</Heading>
				<Text className="text-muted">References in Quran and Hadith to the names of Allah</Text>
			</Box>

			<Quran />			

			<Hadith />

		</Container>

	);
}

export default References;