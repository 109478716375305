
import HomeFeatures from './home-features'
import HomeIntro from './home-intro'


const Home = () => {
	return (

		<>

			<HomeIntro />

			<HomeFeatures />

		</>
	);
}

export default Home;