import { useEffect, useState } from 'react';

import DB from '../../data/DB.json'
import Surah from '../../data/surah.json'

import { Box, Heading, HStack, Text } from '@chakra-ui/react';


type Props = {
	fieldReference: string
}


const NamesReference = ({ fieldReference }: Props) => {

	// Extract the References from the JSON
	// Fetch the Data and Display
	// e.g.   "Reference": "2:153, 3:200, 103:3"
	// We assume that this component was call with valid reference format
	const [referenceData, setReferenceData] = useState([])


	// English
	useEffect(() => {

		// Example: this.props.fieldReference = '2:153, 3:200, 103:3'
		// Example: Convert to an Array

		// ["2:153", " 3:200", " 103:3"]
		const resArray = fieldReference.split(",");
		let finalData: any = []

		// Map over the results and for ELEMENT we need to do an API call
		// Then add that to the contact previous state value
		resArray.forEach((quranReference) => {

			const splitQuranReference = quranReference.split(":")
			const referenceSurah = splitQuranReference[0].trim()
			const referenceVerse = splitQuranReference[1].trim()

			// console.log(referenceSurah + ":" + referenceVerse)

			// For each reference we extract the entry from Quran/English DB
			const newData = DB.filter((entry) => (
				entry.sura === parseInt(referenceSurah) &&
				entry.ayah === parseInt(referenceVerse)
			))

			// Concat the results 
			finalData = (finalData.concat(newData))
		});

		// Now Update the UI
		setReferenceData(finalData)
		// console.log(finalData)

	}, [fieldReference]);


	return (
		<>

			<Box shadow={"md"} mb={5} p={5}	>
				<Heading as="h3" fontSize={"lg"}>References</Heading>
			</Box>
			{
				referenceData.length > 0 && referenceData &&
				/*
				 
				 DATA: ReferenceData has 4 Fields
				 1. Sura e.g. 1
				 2. Verse e.g. 2
				 3. eng
				 5. arb
	
				DATA: SURAH: also has 4 fields
				"ID": 1,
				"Trans": "Al Faatiha",
				"Arabic": "الفاتحة",
				"Translation": "The Opener"
	
				*/


				referenceData.map((reference: any, index) => (

					<Box shadow={"md"} mb={10}
						key={index}
						className="p-0 mb-5"
					>

						<Box p={5}>

							<HStack>
								<Text>Surah {reference.sura} : </Text>
								<Text>{Surah[reference.sura - 1].Trans} </Text>
								<Text>({Surah[reference.sura - 1].Translation}) - </Text>
								<Text>Verse: {reference.ayah}</Text>
							</HStack>

						</Box>

						<Box p={5}>
							<Text className="arabic">{reference.arb}</Text>
							<Text>{reference.eng}</Text>
						</Box>

					</Box>
				))

			}
		</>
	);

}

export default NamesReference;