
import { Box, Divider, Heading, Text } from '@chakra-ui/react';


const Hadith = () => {
	return (
		<>

			<Heading as="h2">Hadith</Heading>

			<Box shadow='md' p={3} mb={3}>
				<Text as="em">
					Abu Hurairah reported that Allah has ninety-nine Names, i.e., one hundred minus one, and whoever believes in their meanings and
					acts accordingly, will enter Paradise; and Allah is witr (one) and loves 'the witr' (i.e., odd numbers).
				</Text>
				<Divider />
				<Text as="cite">Vol. 8, Book 75, Hadith 419: Sahih Bukhari</Text>
			</Box>

			<Box shadow='md' p={3} mb={3}>
				<Text as="em">
					Allah's Messenger (ﷺ) said, "Allah has ninety-nine Names, one-hundred less one;
					and he who memorized them all by heart will enter Paradise." To count something means to know it by heart.
				</Text>
				<Divider />
				<Text as="cite">Vol. 9, Book 93, Hadith 48: Sahih Bukhari</Text>
			</Box>

		</>
	)
}

export default Hadith;