const Page404 = () => {

	document.title = "404 - Page not Found"

	return (
		<>
			<h2>No page found</h2>
		</>
	);
}

export default Page404;