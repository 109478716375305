import constants from '../../data/constants';

import { FaBrain } from 'react-icons/fa';
import { IconButton } from '@chakra-ui/react';
import { useEffect } from 'react';
import HelperLocalStorage from '../../data/helperLocalstorage';


type Props = {
	nameNumber: number
	isNameMemorized: boolean
	setIsNameMemorized: Function
}

const LessonComplete = ({ nameNumber, isNameMemorized, setIsNameMemorized }: Props) => {

	// User clicks on Bookmark Icon
	const nameMemorized = (nameNumber: number) => {

		// If Lesson is already marked as Completed
		if (isNameMemorized) {
			// Updating Local Stroage
			localStorage.setItem(constants.KEY_NameMemory + nameNumber.toString(), JSON.stringify(false));
			setIsNameMemorized(false)
		}

		// Otherwise, they are simply attempting to mark lesson as completed
		else {
			localStorage.setItem(constants.KEY_NameMemory + nameNumber.toString(), JSON.stringify(true));
			setIsNameMemorized(true)
		}
	}

	// Update UI
	useEffect(() => {
		setIsNameMemorized(HelperLocalStorage.isNameMemorized(nameNumber))
	}, [nameNumber, setIsNameMemorized]);


	return (
		<IconButton
			aria-label='Mark as Memorized'
			icon={<FaBrain />}
			onClick={() => nameMemorized(nameNumber)}
			colorScheme={isNameMemorized ? "green" : "gray"}
		/>
	);
}


export default LessonComplete;