import Podcasts from '../data/audio-podcasts.json'

import { Flex, Alert, Heading, Box, Image, Button, Text, Link } from '@chakra-ui/react'
import { FaExternalLinkAlt } from "react-icons/fa";

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


const audioInpsire = 'https://media.blubrry.com/muslimcentralaudio/podcasts.muslimcentral.com/faith-iq/faith-iq-muslimcentral.com-should-i-memorize-the-99-names-of-allah-2019-01-26-10-36-11.mp3'


const AudioLectures = () => {

	return (

		<>

			<Box mb={10}>

				<Heading as="h2" fontSize={"2xl"}>Motivation</Heading>

				<Text>Series related to the names of Allah. Stream or Download, powered by Muslim Central.</Text>

				<Alert role="info" mb={1}>
					Listen to this 1 Minute Audio Clip: "Should I Memorize the 99 Names of Allah"
				</Alert>

				<AudioPlayer
					src={audioInpsire}
				/>

			</Box>


			<Heading as="h2" fontSize={"2xl"}>Lectures</Heading>

			<Flex wrap={"wrap"} gap='4'>

				{

					// Pass the RSS Feed for that series to Audio Lectures Podcast JS
					Podcasts.map((item) => (

						<Box
							key={item.url}
							boxShadow='base'
							p={5}
							minWidth={200}
							maxWidth={200}
						>

							<Image
								src={item.image}
								alt={item.Name}
							/>

							<Heading size="sm">{item.Name}</Heading>
							<Heading size="xs">{item.Series}</Heading>

							<Link href={item.url} isExternal>
								<Button> Listen <FaExternalLinkAlt /></Button>
							</Link>

						</Box>
					))

				}
			</Flex>

		</>
	);

}

export default AudioLectures;