import { Container, Heading } from "@chakra-ui/react";

import AudioLectures from "./audio-lectures";
import AudioAllNames from "./audio-all-names";



const Audio = () => {

	return (

		<Container maxW='container.md' mt={10} mb={20}>

			<Heading>Audio</Heading>

			<AudioAllNames />

			<AudioLectures />

		</Container >
	);

}

export default Audio;