const features =
	[
		{
			id: 1,
			title: 'Content',
			text: 'Learn and Memorize the 99 Names of Allah. Arabic, English Translation and Transliteration.'
		},
		{
			id: 2,
			title: 'Audio',
			text: 'Audio Recordings are recited by a qualified reciter & with Ijazah to ensure correct pronunciation'
		},
		{
			id: 3,
			title: 'Details',
			text: 'Explanations and References to Quran and Hadith regarding the names of Allah, as well references per name in the Quran.'
		},
		{
			id: 4,
			title: 'Devices',
			text: '99 Names of Allah may be accessed from your desktop, tablet, mobile phone, television and more.'
		},
		{
			id: 5,
			title: 'Platforms',
			text: 'Compatible on most platforms including Android, iOS, Windows, Linux & more. No Ads & completely FREE'
		},
		{
			id: 6,
			title: 'Install',
			text: 'To install 99Names of Allah, simply “Add to Home Screen” via pop-up or via Browser Menu. Yes! Its that simple. No need for searches at any app store.'
		}

	]

export default features