import { Box, HStack, Tag, Heading, VStack, Text } from "@chakra-ui/react";


import dataNames from '../../data/names.json'


type Props = {
	nameNumber: number
}


const NameHeader = ({ nameNumber }: Props) => {

	/*
	 From the names JSON for the current name, get the following data
	*/
	const arrayIndex = nameNumber - 1;
	const fieldNumber = dataNames[arrayIndex]["ID"]
	const fieldClassicArabic = dataNames[arrayIndex]["Classical Arabic"]
	const fieldIP = dataNames[arrayIndex]["IPA"]
	const fieldTranslation = dataNames[arrayIndex]["Translation"]


	return (
		<Box shadow={"md"} mb={10} >

			{
				// Header
			}

			<Box p={5} bgColor="pink.500">
				<HStack>
					<Tag>{fieldNumber}</Tag>
					<Heading> {fieldIP}</Heading>
				</HStack>
			</Box>


			<Box p={5}>
				<VStack>
					<Text className="arabic">{fieldClassicArabic}</Text>
					<Text>{fieldTranslation}</Text>
				</VStack>
			</Box>

		</Box>
	);
}

export default NameHeader;