import {
	Box,
	Flex,
	HStack,
	Link,
	IconButton,
	Button,
	Menu,
	MenuButton,
	useDisclosure,
	Stack,
	useColorMode
} from '@chakra-ui/react';

import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { FaShareAlt } from 'react-icons/fa';

import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'

import { ReactComponent as LogoLight } from '../assets/images/logo.svg';
import { ReactComponent as LogoDark } from '../assets/images/logo-dark.svg';


const Links =
	[
		{ "icon": "home", "path": "/", "title": "Home" },
		{ "icon": "list-ol", "path": "/names", "title": "Names" },
		{ "icon": "brain", "path": "/quiz", "title": "Quiz" },
		{ "icon": "file-audio", "path": "/audio", "title": "Audio" },

		{ "icon": "info", "path": "/changelog", "title": "Changelog" },
		{ "icon": "info", "path": "/about", "title": "About" },
		{ "icon": "envelope", "path": "/contact", "title": "Contact" },
		// { "icon": "donate", "path": "/donate", "title": "Donate" }
	]


const SideNavPage = () => {


	const handleShare = () => {

		if (navigator.share) {
			navigator
				.share({
					title: document.title,
					url: "https://99names.app"
				})
				.then(() => {
					console.log("Shared successfully.");
				})
				.catch(error => {
					console.log("There was an error sharing:", error);
				});
		}
	}

	const { colorMode, toggleColorMode } = useColorMode();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();


	return (

		<Box px={4}>
			<Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
				<IconButton
					size={'md'}
					icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
					aria-label={'Open Menu'}
					display={{ md: 'none' }}
					onClick={isOpen ? onClose : onOpen}
				/>
				<HStack spacing={8} alignItems={'center'}>

					<Box onClick={() => navigate("./")} cursor={"pointer"}>

						{
							colorMode === 'light' &&
							<LogoLight />
						}

						{
							colorMode === 'dark' &&
							<LogoDark />
						}

					</Box>

					<HStack

						spacing={4}
						display={{ base: 'none', md: 'flex' }}>
						{Links.map((link, index) => (
							<Link key={index} as={ReactRouterLink} to={link.path}>{link.title}</Link>
						))}
						<Link href='https://muslimcentral.com/donate' isExternal>
							<Button colorScheme='pink'>
								Donate <ExternalLinkIcon mx='2px' />
							</Button>
						</Link>
					</HStack>
				</HStack>

				<Flex alignItems={'center'}>
					<Menu>

						<MenuButton
							as={Button}
							size={'sm'}
							cursor={'pointer'}
							minW={0}
							onClick={() => handleShare()}
							mr={2}
						>
							<FaShareAlt />
						</MenuButton>

						<MenuButton
							as={Button}
							size={'sm'}
							cursor={'pointer'}
							minW={0}
							onClick={toggleColorMode}>
							{colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
						</MenuButton>
					</Menu>
				</Flex>

			</Flex>

			{isOpen ? (
				<Box pb={4} display={{ md: 'none' }}>
					<Stack as={'nav'} spacing={4}>
						{Links.map((link, index) => (
							<Link key={index} as={ReactRouterLink} to={link.path}>{link.title}</Link>
						))}
						<Link href='https://muslimcentral.com/donate' isExternal>
							<Button colorScheme='pink'>
								Donate <ExternalLinkIcon mx='2px' />
							</Button>
						</Link>
					</Stack>
				</Box>
			) : null}
		</Box>

	)
}

export default (SideNavPage);