// get reciter
import Constants from "./constants"


export module HelperLocalStorage {


	export function getBookmarkNumber(): number {

		const intial: string = localStorage.getItem(Constants.KEY_BookmarkedName) || ''

		if (intial === '') {
			return 1
		}

		return JSON.parse(intial)
	}


	export function isNameMemorized(nameNumber: number): boolean {

		const intial: string = localStorage.getItem(Constants.KEY_NameMemory + nameNumber.toString()) || ''

		if (intial === '') {
			return false
		}

		return JSON.parse(intial)
	}


}


export default HelperLocalStorage