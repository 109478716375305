import { useState, useEffect } from "react";

import { IconButton } from "@chakra-ui/react";
import { FaBookmark } from "react-icons/fa";

import HelperLocalStorage from "../../data/helperLocalstorage";
import constants from "../../data/constants";



type Props = {
	nameNumber: number
}


const LessonBookmark = ({ nameNumber }: Props) => {

	const [bookmark, setBookmark] = useState(nameNumber === HelperLocalStorage.getBookmarkNumber())

	// User clicks on Bookmark Icon
	const bookmarkName = (nameNumber: number) => {

		// If the user bookmarks a Lesson already bookedmarked   
		if (nameNumber === HelperLocalStorage.getBookmarkNumber()) {
			localStorage.setItem(constants.KEY_BookmarkedName, JSON.stringify(0));
			setBookmark(false)
		}

		// Otherwise, they are simply attempting to bookmark the current lesson
		else {
			localStorage.setItem(constants.KEY_BookmarkedName, JSON.stringify(nameNumber));
			setBookmark(true)
		}
	}

	// Update UI
	useEffect(() => {
		setBookmark(nameNumber === HelperLocalStorage.getBookmarkNumber())
	}, [nameNumber]);

	return (
		<IconButton
			aria-label='Bookmark Name'
			icon={<FaBookmark />}
			onClick={() => bookmarkName(nameNumber)}
			colorScheme={bookmark ? "green" : "gray"}
		/>
	)
}


export default LessonBookmark;