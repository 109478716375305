import { Box, Heading, Text } from '@chakra-ui/react';


type Props = {
	fieldReference: string
}


const NamesReferenceNone = ({ fieldReference }: Props) => {
	return (

		<Box shadow={"md"} mb={10}>

			<Box p={5}>
				<Heading as="h2" fontSize="lg">Quran References</Heading>
			</Box>

			<Box p={5}>
				<Text>
					{fieldReference}
				</Text>
			</Box>

		</Box >
	)
}

export default NamesReferenceNone;