import { Box, Button, Heading, Link, Text } from "@chakra-ui/react";
import { FaExternalLinkAlt } from 'react-icons/fa';


// Organisation
const AboutPageTop = () => {

	return (

		<Box mt={ 10 }>

			<Heading as='h2' fontSize={ "medium" }>Organisation</Heading>

			<Text>This project is brought to your by Muslim Central, digital dawah non-profit organisation.</Text>
			<Text>It was founded by a brother from Cape Town, South Africa and <Text as="em">Mufti Ismail Menk</Text> as co-director.</Text>

			<Link href='https://muslimcentral.com/about/' isExternal>
				<Button leftIcon={ <FaExternalLinkAlt /> }>
					More about our Organisation & Projects
				</Button>
			</Link>

		</Box>
	)
}

export default AboutPageTop;
