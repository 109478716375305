import { Container, Box, Heading, Text, UnorderedList, OrderedList, ListItem } from '@chakra-ui/react'


import AboutTop from './aboutTop';
import References from './ref';


const AboutPage = () => {

	return (

		<Container maxW='container.sm' mt={10} mb={20}>

			<Heading>About</Heading>

			<AboutTop />

			{
				// Project
			}

			<Box mt={10}>

				<Heading as='h2' fontSize={"medium"}>Project</Heading>

				<Text>
					The purpose of Web App is to assist in the learning, memorizing and pronunciation of Allah’s 99 perfect names.
				</Text>

				<OrderedList>
					<ListItem> At Muslim Central we build websites/apps that do not contain any advertisements which is one of its differentiators.</ListItem>
					<ListItem> At Muslim Central, we use Progressive Web App technology that run on all devices thereby guaranteeing you access to the app from anywhere.</ListItem>
					<ListItem>  99Names of Allah can be used as a website on the desktop or installed on mobile devices without going via an app store.</ListItem>
					<ListItem> All our apps are FREE. Muslim Central’s policy is knowledge is free. However, that should not stop you from donating generously because sadaqa is an important virtue in Islam.</ListItem>
				</OrderedList>

			</Box>

			<Box mt={10}>

				<Heading as='h2' fontSize={"medium"}>Content</Heading>

				<UnorderedList>
					<ListItem> Arabic Name</ListItem>
					<ListItem> Translation of Name</ListItem>
					<ListItem> Transliteration</ListItem>
					<ListItem> Explanations</ListItem>
					<ListItem> Quranic references</ListItem>
					<ListItem> Transliteration</ListItem>
				</UnorderedList>

			</Box>

			<Box mt={10}>

				<Heading as='h2' fontSize={"medium"}>Features</Heading>

				<UnorderedList>
					<ListItem> Memorization Tracker</ListItem>
					<ListItem> Bookmarker</ListItem>
					<ListItem> Audio Recordings recited with correct pronounciation</ListItem>
					<ListItem> Quiz</ListItem>
					<ListItem> Lectures</ListItem>
				</UnorderedList>

			</Box>

			<References />

			<Box mt={10}>

				<Heading as='h2' fontSize={"medium"}>Legal</Heading>
				<Text>
					All rights reserved. No part of this website may be reproduced, distributed, or transmitted in any form or by any means, including photocopying, recording, or other electronic or mechanical methods, without the prior written permission of the Muslim Central NPO, except in the case of brief quotations embodied in critical reviews and certain other noncommercial uses permitted by copyright law.
				</Text>

			</Box>

		</Container>
	);
}

export default AboutPage;
