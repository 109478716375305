import name01 from '../assets/explanations-md/01.md'
import name02 from '../assets/explanations-md/02.md'
import name03 from '../assets/explanations-md/03.md'
import name04 from '../assets/explanations-md/04.md'
import name05 from '../assets/explanations-md/05.md'
import name06 from '../assets/explanations-md/06.md'
import name07 from '../assets/explanations-md/07.md'
import name08 from '../assets/explanations-md/08.md'
import name09 from '../assets/explanations-md/09.md'
import name10 from '../assets/explanations-md/10.md'
import name11 from '../assets/explanations-md/11.md'
import name12 from '../assets/explanations-md/12.md'
import name13 from '../assets/explanations-md/13.md'
import name14 from '../assets/explanations-md/14.md'
import name15 from '../assets/explanations-md/15.md'
import name16 from '../assets/explanations-md/16.md'
import name17 from '../assets/explanations-md/17.md'
import name18 from '../assets/explanations-md/18.md'
import name19 from '../assets/explanations-md/19.md'
import name20 from '../assets/explanations-md/20.md'
import name21 from '../assets/explanations-md/21.md'
import name22 from '../assets/explanations-md/22.md'
import name23 from '../assets/explanations-md/23.md'
import name24 from '../assets/explanations-md/24.md'
import name25 from '../assets/explanations-md/25.md'
import name26 from '../assets/explanations-md/26.md'
import name27 from '../assets/explanations-md/27.md'
import name28 from '../assets/explanations-md/28.md'
import name29 from '../assets/explanations-md/29.md'
import name30 from '../assets/explanations-md/30.md'
import name31 from '../assets/explanations-md/31.md'
import name32 from '../assets/explanations-md/32.md'
import name33 from '../assets/explanations-md/33.md'
import name34 from '../assets/explanations-md/34.md'
import name35 from '../assets/explanations-md/35.md'
import name36 from '../assets/explanations-md/36.md'
import name37 from '../assets/explanations-md/37.md'
import name38 from '../assets/explanations-md/38.md'
import name39 from '../assets/explanations-md/39.md'
import name40 from '../assets/explanations-md/40.md'
import name41 from '../assets/explanations-md/41.md'
import name42 from '../assets/explanations-md/42.md'
import name43 from '../assets/explanations-md/43.md'
import name44 from '../assets/explanations-md/44.md'
import name45 from '../assets/explanations-md/45.md'
import name46 from '../assets/explanations-md/46.md'
import name47 from '../assets/explanations-md/47.md'
import name48 from '../assets/explanations-md/48.md'
import name49 from '../assets/explanations-md/49.md'
import name50 from '../assets/explanations-md/50.md'
import name51 from '../assets/explanations-md/51.md'
import name52 from '../assets/explanations-md/52.md'
import name53 from '../assets/explanations-md/53.md'
import name54 from '../assets/explanations-md/54.md'
import name55 from '../assets/explanations-md/55.md'
import name56 from '../assets/explanations-md/56.md'
import name57 from '../assets/explanations-md/57.md'
import name58 from '../assets/explanations-md/58.md'
import name59 from '../assets/explanations-md/59.md'
import name60 from '../assets/explanations-md/60.md'
import name61 from '../assets/explanations-md/61.md'
import name62 from '../assets/explanations-md/62.md'
import name63 from '../assets/explanations-md/63.md'
import name64 from '../assets/explanations-md/64.md'
import name65 from '../assets/explanations-md/65.md'
import name66 from '../assets/explanations-md/66.md'
import name67 from '../assets/explanations-md/67.md'
import name68 from '../assets/explanations-md/68.md'
import name69 from '../assets/explanations-md/69.md'
import name70 from '../assets/explanations-md/70.md'
import name71 from '../assets/explanations-md/71.md'
import name72 from '../assets/explanations-md/72.md'
import name73 from '../assets/explanations-md/73.md'
import name74 from '../assets/explanations-md/74.md'
import name75 from '../assets/explanations-md/75.md'
import name76 from '../assets/explanations-md/76.md'
import name77 from '../assets/explanations-md/77.md'
import name78 from '../assets/explanations-md/78.md'
import name79 from '../assets/explanations-md/79.md'
import name80 from '../assets/explanations-md/80.md'
import name81 from '../assets/explanations-md/81.md'
import name82 from '../assets/explanations-md/82.md'
import name83 from '../assets/explanations-md/83.md'
import name84 from '../assets/explanations-md/84.md'
import name85 from '../assets/explanations-md/85.md'
import name86 from '../assets/explanations-md/86.md'
import name87 from '../assets/explanations-md/87.md'
import name88 from '../assets/explanations-md/88.md'
import name89 from '../assets/explanations-md/89.md'
import name90 from '../assets/explanations-md/90.md'
import name91 from '../assets/explanations-md/91.md'
import name92 from '../assets/explanations-md/92.md'
import name93 from '../assets/explanations-md/93.md'
import name94 from '../assets/explanations-md/94.md'
import name95 from '../assets/explanations-md/95.md'
import name96 from '../assets/explanations-md/96.md'
import name97 from '../assets/explanations-md/97.md'
import name98 from '../assets/explanations-md/98.md'
import name99 from '../assets/explanations-md/99.md'


const nameExp = [name01, name02, name03, name04, name05, name06, name07, name08, name09, name10, name11, name12, name13, name14, name15, name16, name17, name18, name19, name20, name21, name22, name23, name24, name25, name26, name27, name28, name29, name30, name31, name32, name33, name34, name35, name36, name37, name38, name39, name40, name41, name42, name43, name44, name45, name46, name47, name48, name49, name50, name51, name52, name53, name54, name55, name56, name57, name58, name59, name60, name61, name62, name63, name64, name65, name66, name67, name68, name69, name70, name71, name72, name73, name74, name75, name76, name77, name78, name79, name80, name81, name82, name83, name84, name85, name86, name87, name88, name89, name90, name91, name92, name93, name94, name95, name96, name97, name98, name99];

export default nameExp