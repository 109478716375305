
import A01 from '../assets/audio/01.mp3';
import A02 from '../assets/audio/02.mp3';
import A03 from '../assets/audio/03.mp3';
import A04 from '../assets/audio/04.mp3';
import A05 from '../assets/audio/05.mp3';
import A06 from '../assets/audio/06.mp3';
import A07 from '../assets/audio/07.mp3';
import A08 from '../assets/audio/08.mp3';
import A09 from '../assets/audio/09.mp3';
import A10 from '../assets/audio/10.mp3';
import A11 from '../assets/audio/11.mp3';
import A12 from '../assets/audio/12.mp3';
import A13 from '../assets/audio/13.mp3';
import A14 from '../assets/audio/14.mp3';
import A15 from '../assets/audio/15.mp3';
import A16 from '../assets/audio/16.mp3';
import A17 from '../assets/audio/17.mp3';
import A18 from '../assets/audio/18.mp3';
import A19 from '../assets/audio/19.mp3';
import A20 from '../assets/audio/20.mp3';
import A21 from '../assets/audio/21.mp3';
import A22 from '../assets/audio/22.mp3';
import A23 from '../assets/audio/23.mp3';
import A24 from '../assets/audio/24.mp3';
import A25 from '../assets/audio/25.mp3';
import A26 from '../assets/audio/26.mp3';
import A27 from '../assets/audio/27.mp3';
import A28 from '../assets/audio/28.mp3';
import A29 from '../assets/audio/29.mp3';
import A30 from '../assets/audio/30.mp3';
import A31 from '../assets/audio/31.mp3';
import A32 from '../assets/audio/32.mp3';
import A33 from '../assets/audio/33.mp3';
import A34 from '../assets/audio/34.mp3';
import A35 from '../assets/audio/35.mp3';
import A36 from '../assets/audio/36.mp3';
import A37 from '../assets/audio/37.mp3';
import A38 from '../assets/audio/38.mp3';
import A39 from '../assets/audio/39.mp3';
import A40 from '../assets/audio/40.mp3';
import A41 from '../assets/audio/41.mp3';
import A42 from '../assets/audio/42.mp3';
import A43 from '../assets/audio/43.mp3';
import A44 from '../assets/audio/44.mp3';
import A45 from '../assets/audio/45.mp3';
import A46 from '../assets/audio/46.mp3';
import A47 from '../assets/audio/47.mp3';
import A48 from '../assets/audio/48.mp3';
import A49 from '../assets/audio/49.mp3';
import A50 from '../assets/audio/50.mp3';
import A51 from '../assets/audio/51.mp3';
import A52 from '../assets/audio/52.mp3';
import A53 from '../assets/audio/53.mp3';
import A54 from '../assets/audio/54.mp3';
import A55 from '../assets/audio/55.mp3';
import A56 from '../assets/audio/56.mp3';
import A57 from '../assets/audio/57.mp3';
import A58 from '../assets/audio/58.mp3';
import A59 from '../assets/audio/59.mp3';
import A60 from '../assets/audio/60.mp3';
import A61 from '../assets/audio/61.mp3';
import A62 from '../assets/audio/62.mp3';
import A63 from '../assets/audio/63.mp3';
import A64 from '../assets/audio/64.mp3';
import A65 from '../assets/audio/65.mp3';
import A66 from '../assets/audio/66.mp3';
import A67 from '../assets/audio/67.mp3';
import A68 from '../assets/audio/68.mp3';
import A69 from '../assets/audio/69.mp3';
import A70 from '../assets/audio/70.mp3';
import A71 from '../assets/audio/71.mp3';
import A72 from '../assets/audio/72.mp3';
import A73 from '../assets/audio/73.mp3';
import A74 from '../assets/audio/74.mp3';
import A75 from '../assets/audio/75.mp3';
import A76 from '../assets/audio/76.mp3';
import A77 from '../assets/audio/77.mp3';
import A78 from '../assets/audio/78.mp3';
import A79 from '../assets/audio/79.mp3';
import A80 from '../assets/audio/80.mp3';
import A81 from '../assets/audio/81.mp3';
import A82 from '../assets/audio/82.mp3';
import A83 from '../assets/audio/83.mp3';
import A84 from '../assets/audio/84.mp3';
import A85 from '../assets/audio/85.mp3';
import A86 from '../assets/audio/86.mp3';
import A87 from '../assets/audio/87.mp3';
import A88 from '../assets/audio/88.mp3';
import A89 from '../assets/audio/89.mp3';
import A90 from '../assets/audio/90.mp3';
import A91 from '../assets/audio/91.mp3';
import A92 from '../assets/audio/92.mp3';
import A93 from '../assets/audio/93.mp3';
import A94 from '../assets/audio/94.mp3';
import A95 from '../assets/audio/95.mp3';
import A96 from '../assets/audio/96.mp3';
import A97 from '../assets/audio/97.mp3';
import A98 from '../assets/audio/98.mp3';
import A99 from '../assets/audio/99.mp3';

const audio = [A01, A02, A03, A04, A05, A06, A07, A08, A09, A10, A11, A12, A13, A14, A15, A16, A17, A18, A19, A20, A21, A22, A23, A24, A25, A26, A27, A28, A29, A30,
    A31, A32, A33, A34, A35, A36, A37, A38, A39, A40, A41, A42, A43, A44, A45, A46, A47, A48, A49, A50, A51, A52, A53, A54, A55, A56, A57, A58, A59,
    A60, A61, A62, A63, A64, A65, A66, A67, A68, A69, A70, A71, A72, A73, A74, A75, A76, A77, A78, A79, A80, A81, A82, A83, A84, A85, A86, A87, A88, A89,
    A90, A91, A92, A93, A94, A95, A96, A97, A98, A99];
export default audio;