import { Link as RouterLink } from "react-router-dom";

import { Box, Center, useColorMode, HStack, IconButton, Link, VStack } from "@chakra-ui/react";

import NameAudio from './footer-audio'
import NamesMemorized from './footer-memorized'
import NamesBookmarked from './footer-bookmark'

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useState } from "react";
import HelperLocalStorage from "../../data/helperLocalstorage";
import ProgressBar from "./footer-progress";



type Props = {
	nameNumber: number
}


const lightBackground = "rgb(245, 192, 216)"
const darkBackground = "gray.600"



const NameFooter = ({ nameNumber }: Props) => {

	const back = "/" + (nameNumber - 1).toString()
	const forward = "/" + (nameNumber + 1).toString()

	// The user may update this and we need to update the progress component hence managing it here
	const [isNameMemorized, setIsNameMemorized] = useState(HelperLocalStorage.isNameMemorized(nameNumber))
	const { colorMode } = useColorMode();

	return (

		<Box position={"fixed"} bottom={0} p={1} width={"100%"} bgColor={colorMode === 'light' ? lightBackground : darkBackground} >

			<Center>

				<VStack>
					<ProgressBar />

					<HStack>

						<Link to={nameNumber !== 1 ? back : ''} as={RouterLink}>
							<IconButton aria-label={''} icon={<FaArrowLeft />} />
						</Link>


						<NameAudio nameNumber={nameNumber - 1} />

						<NamesMemorized
							nameNumber={nameNumber}
							isNameMemorized={isNameMemorized}
							setIsNameMemorized={setIsNameMemorized}
						/>

						<NamesBookmarked nameNumber={nameNumber} />

						<Link to={nameNumber !== 99 ? forward : ''} as={RouterLink}>
							<IconButton aria-label={''} icon={<FaArrowRight />} />
						</Link>

					</HStack>

				</VStack>

			</Center>
		</Box>


	);
}


export default NameFooter;