import { Link as RouterLink } from "react-router-dom";

import {
	Box,
	Heading,
	Container,
	Text,
	Button,
	Stack,
	Link,
	HStack
} from '@chakra-ui/react';
import { FaBookmark, FaBrain } from 'react-icons/fa';
import HelperLocalStorage from "../data/helperLocalstorage";



const HomeIntro = () => {

	const currentBookmark = HelperLocalStorage.getBookmarkNumber()
	console.log("currentBookmark = " + currentBookmark)

	return (

		<Container maxW={'3xl'}>
			<Stack
				as={Box}
				textAlign={'center'}
				spacing={{ base: 8, md: 14 }}
				py={{ base: 20, md: 36 }}>
				<Heading
					fontWeight={600}
					fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
					lineHeight={'110%'}>
					99 Names of Allah
					<br />

					<Text as={'span'} color={'#e60188'}>
						Desktop, Tablet Mobile Friendly</Text>
				</Heading>
				<Text fontSize={'xl'}>
					"Allah has ninety-nine Names, one-hundred less one; and he who memorized them all by heart will enter Paradise."
				</Text>

				<HStack
					direction={'column'}
					spacing={3}
					align={'center'}
					alignSelf={'center'}
					position={'relative'}
				>

					<Link as={RouterLink} to="/1">
						<Button
							colorScheme="pink"
							leftIcon={<FaBrain />}
							rounded={'full'}
							px={6}
						>Begin
						</Button>
					</Link>

					<Link as={RouterLink} to={"./" + currentBookmark}>
						<Button
							leftIcon={<FaBookmark />}
							rounded={'full'}
							px={6}
							colorScheme="green"
						>Continue
						</Button>
					</Link>

				</HStack>

			</Stack>

		</Container >

	);
}


export default HomeIntro