import { BrowserRouter, Route, Routes } from "react-router-dom";

import Header from '../common/header'

import Home from '../common/home'
import About from '../common/about'
import Contact from '../common/contact'
import Page404 from '../common/404'


// Feature pages
import Name from '../components/name/name'
import Names from '../components/names'
import Audio from '../components/audio'
import QuizHome from '../components/quiz'


// Data
import dataNames from '../data/names.json'
import Changelog from "../common/changelog";


const AppRoutes = () => {

	return (

		<BrowserRouter>

			<Header />

			<Routes>

				<Route path="/" element={<Home />} />

				<Route path="/about" element={<About />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/changelog" element={<Changelog />} />

				<Route path="/audio" element={<Audio />} />
				<Route path="/names" element={<Names />} />

				<Route path="/quiz" element={<QuizHome />} />

				{
					// Create a Route for every Name
					dataNames.map((item: any) => (
						<Route
							key={item["ID"]}
							path={"/" + item["ID"]}
							element={<Name nameNumber={item["ID"]} />}
						/>
					))

				}

				<Route path="*" element={<Page404 />} />

			</Routes>

		</BrowserRouter >

	);
}

export default AppRoutes;