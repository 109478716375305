import { Box, Button, Container, Heading, Link, Text } from "@chakra-ui/react";

import { FaExternalLinkAlt } from "react-icons/fa";

import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
} from '@chakra-ui/react'


const Contact = () => {
	return (

		<Container maxW='container.sm' mt={10} mb={20}>

			<Heading as='h1' mb={5}>Contact</Heading>

			<Accordion mb={5}>
				<AccordionItem>
					<h2>
						<AccordionButton>
							<Box as="span" flex='1' textAlign='left'>
								Will the App include other books in the future? </Box>
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4}>
						We may do so if the demand is there, insha'Allah. At the time of writing, were waiting for the content for the
						Madani script.
					</AccordionPanel>
				</AccordionItem>

				<AccordionItem>
					<h2>
						<AccordionButton>
							<Box as="span" flex='1' textAlign='left'>
								We may do so if the demand is there.
							</Box>
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4}>
						The App is around 7M once it is uncompressed onto your device.</AccordionPanel>
				</AccordionItem>
			</Accordion>

			<Box mb={5}>
				<Text>Please keep inquiries directly, related to our project.</Text>
			</Box>

			<Box mb={5}>
				<Link href='mailto:support@muslimcentral.com?subject=99Names.com:' isExternal>
					<Button leftIcon={<FaExternalLinkAlt />}>
						Email Support
					</Button>
				</Link>
			</Box>

		</Container>
	)
}

export default Contact;