import { Box, Container, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";


const Changelog = () => {

	return (

		<Container maxW='container.sm' mt={10} mb={20}>

			<Heading>Change Log</Heading>

			<Text as="b"> Current Version: </Text>{process.env.REACT_APP_VERSION}

			<Box mt={10}>
				<Heading as="h2" fontSize={"medium"}>[Nov 2023]</Heading>
				<UnorderedList>
					<ListItem>Code Improvements</ListItem>
					<ListItem>Formatting on all news</ListItem>
					<ListItem>Removed Explanation Heading to minizmie screen space</ListItem>
				</UnorderedList>
			</Box>


			<Box mt={10}>
				<Heading as="h2" fontSize={"medium"}>[Feb 2023]</Heading>
				<UnorderedList>
					<ListItem>Code Improvements</ListItem>
				</UnorderedList>
			</Box>

			<Box mt={10}>
				<Heading as="h2" fontSize={"medium"}>[30 May 2022]</Heading>
				<Text>Version 2 is a new MAJOR Release. Lots of internal changes mostly though.</Text>
				<UnorderedList>
					<ListItem>Quizzes split up now Available</ListItem>
					<ListItem>Quiz is now Available</ListItem>
					<ListItem>Audio Page: Added ability to repeat name.</ListItem>
					<ListItem>Audio Page: Listen to all 99 Names</ListItem>
					<ListItem>Quiz moved to a Google Form (coming soon)</ListItem>
					<ListItem>Dark Mode</ListItem>
					<ListItem>Updated Names Page</ListItem>
					<ListItem>Updated Contact Page</ListItem>
					<ListItem>Updated About Page</ListItem>
					<ListItem>Updated Donate Page</ListItem>
					<ListItem>Updated Front Page</ListItem>
					<ListItem>NEW Changelog Page</ListItem>
					<ListItem>Code: Performance Optimization</ListItem>
					<ListItem>Code: Changes to make it easier to maintain and upgrade</ListItem>
					<ListItem>Code: Updated underlying techstack</ListItem>
					<ListItem>Removed Newsletter Integration</ListItem>
					<ListItem>Implemented a "An Update is Available" Feature</ListItem>
				</UnorderedList>
			</Box>

		</Container >

	);
}

export default Changelog;