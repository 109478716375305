import dataNames from '../../data/names.json'

import { Container } from '@chakra-ui/react'


// Children in order
import NameHeader from './name-header'

import NamesExplanation from './name-explanation'
import NamesReference from './name-reference'
import NamesReferenceNone from './name-reference-none'

import Footer from './footer'



type Props = {
	nameNumber: number
}


const Names = ({ nameNumber }: Props) => {

	// From the Names JSON, check if references e.g 2:222 exist
	const fieldReference = dataNames[nameNumber - 1]["Reference"]
	const hasReferences = fieldReference.search(":") === -1

	return (

		<>
			<Container maxW='container.md' mt={10} mb={20} centerContent>

				{
					// e.g. Al-Quddus + Arabic + English
				}
				<NameHeader nameNumber={nameNumber} />

				<NamesExplanation nameNumber={nameNumber} />

				{
					hasReferences ? <NamesReferenceNone fieldReference={fieldReference} /> : <NamesReference fieldReference={fieldReference} />
				}

			</Container>



			<Footer
				nameNumber={nameNumber} />



		</>
	);
}


export default Names;
