// UI
import { ChakraProvider } from '@chakra-ui/react'
import './App.css';

import AppRoutes from "./utils/App-Routes";
import AppUI from './utils/App-UI';

function App() {


	return (

		<ChakraProvider theme={AppUI()} >

			<div className="App">

				<AppRoutes />

			</div>

		</ChakraProvider>

	);

}

export default App;
