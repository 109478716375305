import QuranReferences from '../data/quran-references.json'
import { Box, Heading, Text } from '@chakra-ui/react';


const Quran = () => {
	return (
		<>
			<Heading as="h2">Quran</Heading>

			{
				QuranReferences.map((reference, index) => (

					<Box shadow='md' key={index} p={3} mb={3}>

						<Heading as="h3" fontSize={'medium'} >{reference.Heading}</Heading>

						<Box>
							<Text className="arabic" dir="rtl" mt={5} mb={5}> {reference.Arabic}</Text>
						</Box>

						<Text>{reference.English}</Text>


					</Box>
				))
			}

		</>
	)
}

export default Quran;